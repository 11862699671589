@import "../../variables";
@import "../buttons";

@mixin arrow() {
  position: absolute;
  top: -6px;
  right: 20px;
  content: "";
  width: 10px;
  height: 10px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0;
  border-left: 0;
  transform: rotate(-45deg);
}

@mixin list-menu-banner() {
  position: absolute;
  z-index: 10;
  width: auto;
  max-width: 320px;
  min-width: 183px;

  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 719px) {
    max-width: 220px;
  }
  top: 10px;
  right: -20px;
  padding: 0;
  margin: 0;

  border: 1px solid #dbdcdc;
  border-radius: 6px;
  background-color: white;
  text-align: left;

  ul {
    padding: 0;

    &.list-level-3 {
      position: relative;
    }
  }

  li {
    position: relative;
    margin: 0;
    list-style: none;

    @media screen and (min-width: 720px) {
      &.row-level-3{
        &:first-child {
          &:before {
            @include arrow();
          }
        }
      }
    }
  }

  & > li {

    transition: background-color 0.25s ease-in-out;

    &:not(:last-child) {
      border-bottom: 1px solid #dbdcdc;
    }
    &.no-title {
      background: none;
      min-height: 0;
    }

    &.row-level-3{
      div{
        &.content-level-3{
          font-size: 10px;
          font-weight: bold;
          color: #969797;
          text-transform: uppercase;
        }
      }
    }

  }

  a {
    display: block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-decoration: none;
    color: #2f3844;
    font-size: 15px;

    &:hover {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  span {
    height: 30px;
    line-height: 30px;
    display: inline-block;
    padding: 0 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .arrow-wrapper {
    display: none;
  }
}

@mixin profile() {
  content: "";
  background: url("#{$base-path}/assets/img/profile.svg") no-repeat center center;
  display: flex;
  order: 4;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}



#banner {
  display: flex;
  width: 100%;
  height: 72px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  *, *:before, *:after {
    box-sizing: border-box;
  }

  #digi-news {
    position: relative;
    padding-left: 50px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: flex-start;
    flex: 1 1 auto;
    strong {
      white-space: nowrap;
    }
    #news-wrapper {
      position: relative;
      button {
        font-size: 11px;
      }
      #news-list {
        display: none;
        @include list-menu-banner();
        &.active {
          display: block;
          top: 30px;
        }
        span {
          display: block;
        }
      }
    }

    button {
      position: relative;
      background: $main-color;
      color: #ffffff;
      width: 35px;
      height: 20px;
      border-radius: 100px;
      text-align: left;
      outline: none;
      border: 0;
      cursor: pointer;
      &:after {
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 3px solid #ffffff;
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
      }
    }

    .typer {
      width: 325px;
      max-height: 70px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .icon-down {
      display: inline-block;
      height: 5px;
      transform: rotate(-315deg);
      border-radius: 0.7px;
      background-color: #ffffff;
    }

    & > a {
      margin-left: 5px;
    }

  }

  .additional-menu {
    align-items: center;
    text-align: right;
    display: flex;
    outline: none;

    li {
      list-style: none;
    }

    & > ul {
      display: flex;
      flex-direction: row;
      padding: 0;
      align-items: center;
      & > li {
        position: relative;
        margin-left: 10px;

        &.row-level-2 {
          a {
            display: block;
          }
          & > ul {
            display: none;
            &:before {
              display: block;
              content: "";
              width: 100%;
              height: 35px;
              background-color: rgba(0, 0, 0, 0);
              position: absolute;
              top: -25px;
            }
          }
        }

      }

      ul {
        &.list-level-3 {
          position: absolute;
          right: 0;
          z-index: 100;

        }
      }
    }

  }

  @media screen and (min-width: 720px) {

    &.isLogged {
      .additional-menu {
        .login {
          & > div {
            &:before {
              @include profile();
            }
            & > span {
              display: none;
            }
          }

        }
      }
    }

    .logo {
      flex: 0 0 auto;
      width: 140px;
      height: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
      outline: none;
      &:focus, &:hover {
        .caret {
          border-top-color: #6D6D6D;
        }
      }
      .image {
        width: 100%;
        height: 100%;
      }
      .caret {
        margin-left: 10px;
      }
    }

    .additional-menu {
      flex: 0 0 auto;
      & > ul {
        li {
          &.row-level-2 {
            > a {
              @include btn();
            }

            & > div {
              &.content-level-2 {
                & > span {
                  @include btn();
                }
              }
            }

            &:hover {
              & > ul {
                display: block;
                @include list-menu-banner();
                top: 50px;
                right: 0;
              }
            }
          }
        }
      }
    }

    .caret {
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 5px solid #121212;
      margin-left: auto;
    }
  }

  @media screen and (max-width: 719px) {
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: #ffffff;

    .toggle-mobile-menu {
      position: relative;
      order: 1;
      display: block;
      width: 40px;
      height: 40px;
      background: url("#{$base-path}/assets/img/ic-menu-mobile.svg") no-repeat center center;
      margin: 0 10px;
      cursor: pointer;
      z-index: 99;
      transition: opacity 0.25s ease-in-out;
      outline: none;
    }

    &.isOpen {
      .toggle-mobile-menu {
        opacity: 1;
        background: rgba(0, 0, 0, 0);
      }
      .toggle-mobile-menu:hover {
        opacity: 0.3;
      }
      .toggle-mobile-menu:before, .toggle-mobile-menu:after {
        position: absolute;
        top: 30%;
        left: 50%;
        content: " ";
        height: 16px;
        width: 2px;
        background-color: #ffffff;
      }
      .toggle-mobile-menu:before {
        transform: rotate(45deg);
      }
      .toggle-mobile-menu:after {
        transform: rotate(-45deg);
      }

    }

    #digi-news {
      order: 3;
      strong, .typer {
        display: none;

      }
      #news-list {
        right: -5px;
      }
    }

    .logo {
      width: 140px;
      height: 30px;
      display: flex;
      align-items: center;
      flex: 1;
      order: 2;
      cursor: pointer;
      z-index: 99;
      outline: none;

      .image {
        width: 100%;
        height: 100%;
      }
      .caret {
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 5px solid #121212;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .additional-menu {
      order: 5;

      &:before {
        @include profile();
      }

      &.isOpen {
        display: block;
        & > ul {
          display: block;
        }
      }

      & > ul {
        display: none;
        flex-direction: column;
        @include list-menu-banner();
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        max-width: initial!important;
        border-radius: 0;



        & > li {
          &.row-level-2{
            font-weight: bold;

            a{
              color:$main-color;
              height: 46px;
              font-size: 15px!important;
            }
            span{
              height: 46px;
              line-height: 46px;
            }

            &.login{
              .content-level-2{
                display: none;
              }
            }
            div{
              &.content-level-3{
                & > span{
                  height: 30px;
                  line-height: 30px;
                  font-size: 10px!important;
                  font-weight: bold;
                  color: #969797;
                  text-transform: uppercase;
                  padding-top: 5px;
                }
              }

            }
            ul{
              display: block;
              &:before{
                display: none;
              }
              li{
                &.row-level-4{

                  font-size: 15px;
                  font-weight: normal;
                  a{
                    color: #2f3844;
                    height: 30px;
                  }
                  span{
                    height: 30px;
                    line-height: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}



