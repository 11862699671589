@import "../variables";
#digischool-galaxy-menu{
  .btn {
    min-width: 140px;
    height: 35px;
    text-align: center;
    text-decoration: none;
    padding: 9px 20px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: white;
    border-radius: 100px;
    background-color: $secondary-color;
    border: 2px solid $secondary-color;
    cursor: pointer;
    outline: none;
    transition: all 0.5s ease-in-out;
    user-select: none;
    white-space: nowrap;
    &:hover {
      background: none;
      color: $secondary-color;
    }

    &.reverse, &#connexion {
      background: none;
      color: $secondary-color;

      &:hover {
        color: white;
        background-color: $secondary-color;
        border: 2px solid $secondary-color;
      }

    }
  }
}


@mixin btn() {
    min-width: 100px;
    height: 35px;
    text-align: center;
    text-decoration: none;
    padding: 8px 20px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: white;
    border-radius: 100px;
    background-color: $secondary-color;
    border: 2px solid $secondary-color;
    cursor: pointer;
    outline: none;
    transition: all 0.5s ease-in-out;
    user-select: none;
    white-space: nowrap;
    &:hover {
      background: none;
      color: $secondary-color;
    }

    &.reverse, &#connexion {
      background: none;
      color: $secondary-color;

      &:hover {
        color: white;
        background-color: $secondary-color;
        border: 2px solid $secondary-color;
      }

    }
}