@import "../../variables.scss";

$icon-search-width: 16px;
$icon-search-height: $icon-search-width;

.galaxy-search {
  position: relative;
  min-width: 240px;
  padding-top: 3px;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
  overflow: hidden;
  &.search-active {
    .input-container {
      margin-left: 0;
      opacity: 1;
    }
    &.loading {
      .glxy-loader {
        display: block;
      }
      #magnifying-glass {
        display: none;
      }
    }
  }

  .glxy-loader {
    display: none;
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 101;
  }

  #magnifying-glass {
    position: absolute;
    top: 50%;
    right: 0;
    width: $icon-search-width;
    height: $icon-search-height;
    transform: rotate(-45deg) translateY(-50%);
    transition: background 0.2s ease-in-out;
    border: 2px solid #000000;
    border-radius: 10px;
    outline: none;
    cursor: pointer;

    &::before {
      display: inline-block;
      position: absolute;
      bottom: -8px;
      left: 50%;
      width: 2px;
      height: 6px;
      transform: translateX(-50%);
      transition: background 0.2s ease-in-out;
      border-width: 0;
      background: #000000;
      content: "";
    }
    &:hover, &:focus {
      border-color: $main-color;
      &::before {
        background: $main-color;
      }
    }
  }

  .input-container {
    width: 100%;
    height: 100%;
    margin-left: 100%;
    padding-top: 9px;
    padding-right: calc(16px + 10px);
    transition: all 0.25s ease-in-out;
    border-bottom: 1px solid $main-color;
    opacity: 0;

    input[type="text"] {
      width: 100%;
      height: 100%;
      padding-bottom: 7px;
      border: 0;
      outline: none;
      font-size: 15px;
    }
    ::placeholder {
      color: #dbdcdc;
      font-size: 15px;
      font-style: italic;
    }
  }

  @media screen and (max-width: 719px) {
    order: 6;
    width: 32px;
    min-width: 32px;
    height: 100%;
    &.search-active {
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 100;
    }

    #magnifying-glass {
      margin-right: 10px;
      &::before {
        margin-right: 10px;
      }
    }

    input[type="text"] {
      padding: 0 10px;
    }

  }
}