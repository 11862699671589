.arrow-wrapper {
  position: absolute;
  width: 50px;
  min-height: 50px;
  top:-3px;
  right: 0;
  cursor: pointer;
  outline: none;
  .arrow {
    &:after {
      position: absolute;
      top: 15px;
      right: 20px;
      content: "";
      border: solid #ffffff;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 5px;
      transform: rotate(45deg);
      transition: all 0.25s ease-in-out;
    }
  }

}

.active {
  .arrow {
    &:after {
      transform: rotate(-135deg);
      top: 16px;
    }
  }
}
