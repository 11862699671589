#galaxy {
  @media screen and (min-width: 720px) {
    width: 100%;
    height: 20px;
    background-color: #28313c;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    font-family: "Roboto", sans-serif;

    #galaxy-container {
      display: flex;

      *, *:before, *:after {
        box-sizing: border-box;
      }

      & > a {
        width: 70px;
        border-right: 1px solid #5e646d;
      }

      a {
        padding: 0 10px;
        font-size: 10px;
        line-height: 20px;
        color: #dedfe0;
        text-decoration: none;
        &:hover {

        }
      }

      nav {
        width: 100%;
        position: relative;
        height: 20px;
        overflow: hidden;
        ul {
          position: absolute;
          padding: 0;
          margin: 0;
          left: 0;
          list-style: none;
          transition: left 1s ease-in-out;
          li {
            display: table-cell;
            min-width: 60px;
            text-align: center;
          }
        }
      }

      #galaxy-btn-group {
        min-width: 85px;
        height: 20px;
        background-color: #28313c;
        display: flex;
        align-items: center;

        button {
          margin: 0;
          padding: 0 5px;
          &:first-child {
            padding-right: 0;
          }
          background: none;
          border: 0;
          outline: none;
          cursor: pointer;

          &[disabled] {
            opacity: 0.75;
            cursor: default;
          }

          &#btn-previous, &#btn-next {

            i {
              display: block;
              width: 17+px;
              height: 17px;
              background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDczIiBoZWlnaHQ9IjQ3MyIgdmlld0JveD0iMCAwIDQ3MyA0NzMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlPmFycm93X2xlZnQ8L3RpdGxlPjxwYXRoIGQ9Ik0yMzYuNzc4LjY2N0MxMDYuNTg2LjY2Ny42NjcgMTA2LjU4Ni42NjcgMjM2Ljc3OGMwIDEzMC4xOTEgMTA1LjkxOSAyMzYuMTExIDIzNi4xMTEgMjM2LjExMSAxMzAuMTkxIDAgMjM2LjExMS0xMDUuOTIgMjM2LjExMS0yMzYuMTExQzQ3Mi44ODkgMTA2LjU4NiAzNjYuOTY5LjY2NyAyMzYuNzc4LjY2N3ptMCA0NDEuNjQ2Yy0xMTMuMzM0IDAtMjA1LjUzNi05Mi4yMDYtMjA1LjUzNi0yMDUuNTM1UzEyMy40NDQgMzEuMjQyIDIzNi43NzggMzEuMjQyczIwNS41MzUgOTIuMjA3IDIwNS41MzUgMjA1LjUzNmMwIDExMy4zMjktOTIuMjAxIDIwNS41MzUtMjA1LjUzNSAyMDUuNTM1em0xMTQuNjU2LTIzNC44NjdIMTk2LjI3M3MzNy42MTMtMzcuNjE4IDYwLjkxMi02MC45MDdjOS45NDctOS45NTIgOS45NDctMjYuMDg2IDAtMzYuMDM5LTEuNjQxLTEuNjQtMy4zMDMtMy4yOTctNC45NDQtNC45MzgtOS45NDctOS45NTItMjYuMDgxLTkuOTUyLTM2LjAzMyAwLTMwLjQ5NCAzMC40OS05MS4wMDkgOTEuMDA5LTExMi4xMDYgMTEyLjExMmEyNS40NjMgMjUuNDYzIDAgMCAwLTcuNDY2IDE4LjAxNHYyLjE4MWEyNS40ODIgMjUuNDgyIDAgMCAwIDcuNDY2IDE4LjAxNGMyMS4wOTcgMjEuMDk3IDgxLjYxMiA4MS42MTcgMTEyLjEwNiAxMTIuMTA2IDkuOTUyIDkuOTUyIDI2LjA4NiA5Ljk1MiAzNi4wMzMgMCAxLjY0MS0xLjYzNiAzLjMwMy0zLjMwMiA0Ljk0NC00LjkzOCA5Ljk0Ny05Ljk1MiA5Ljk0Ny0yNi4wODEgMC0zNi4wMzMtMjMuMjk5LTIzLjI5NC02MC45MTItNjAuOTEyLTYwLjkxMi02MC45MTJoMTU1LjE2MWMxNC4wNzUgMCAyNS40OC0xMS40MSAyNS40OC0yNS40OHYtNy43YzAtMTQuMDc1LTExLjQwNS0yNS40OC0yNS40OC0yNS40OHoiIGZpbGw9IiNERURGRTAiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==) no-repeat center center;
              background-size: cover;
            }
          }
          &#btn-next {
            i {
              transform: rotate(-180deg);
            }
          }
        }
      }

    }
  }
  @media screen and (max-width: 719px) {
    display: none;
  }

}