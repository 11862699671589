.btn-service {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 7px;
  background-color: #ffffff;
  border: solid 1px #f4f5f6;
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out;
  img {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover{
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  }
}