@import "../../variables";

#navigation {
  font-family: Roboto, sans-serif;
  *, *:before, *:after {
    box-sizing: border-box;
  }

  nav {
    ul {
      li {
        &.row-level-2 {
          &.home, &.glxy-btn-home {
            i {
              display: block;
              width: 44px;
              height: 100%;
              margin: 0 auto;
              padding: 0 12px;
              background: url(#{$base-path}/assets/img/home.png) no-repeat center center;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 720px) {
    nav {
      position: relative;
      background-color: $main-color;
      ul {
        padding: 0;
        &.list-level-2 {
          height: $menu-height;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        &.list-level-3 {
          position: absolute;
          display: flex;
          top: $menu-height;
          left: 50%;
          width: 100%;
          max-width: 1024px;
          background-color: white;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
          padding: 28px 50px;
          transform: translateX(-50%);
          z-index: 100;

          &.col-1 {
            column-count: 1;
          }
          &.col-2 {
            column-count: 2;
          }
          &.col-3 {
            column-count: 3;
          }
          &.col-4 {
            column-count: 4;
          }
          &.col-5 {
            column-count: 5;
          }
          &.col-6 {
            column-count: 6;
          }
          &.col-7 {
            column-count: 7;
          }
        }

      }

      li {
        height: 100%;
        list-style: none;
        &.row-level-2 {
          .list-level-3 {
            display: none;
          }

          & > a, & > div {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            color: #ffffff;
            font-size: 15px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-decoration: none;

            span {
              line-height: $menu-height;
              padding: 0 12px;
              pointer-events: none;
              white-space: nowrap;
            }

            &:before {
              content: "";
              width: 0;
              height: 4px;
              background-color: #ffffff;
              position: absolute;
              bottom: 0;
              left: 0;
              transition: all 0.3s;
            }
            &:hover {
              &:before {
                width: 100%;
              }
            }
          }

          &:hover {
            .list-level-3 {
              display: flex;
            }
            & > a {
              &:before {
                width: 100%;
              }
            }
          }
        }

        &.row-level-3 {
          position: relative;
          flex: 1;

          &.row-level-3:not(.no-title) ~ .row-level-3.no-title {
            margin-top: 38px;
            ~ .row-level-3.no-title {
              margin-top: 38px;
              ~ .row-level-3.no-title {
                margin-top: 38px;
                ~ .row-level-3.no-title {
                  margin-top: 38px;
                  ~ .row-level-3.no-title {
                    margin-top: 38px;
                  }
                }
              }
            }
          }

          .content-level-3 {
            margin-bottom: 20px;
            span {
              font-size: 16px;
              line-height: 16px;
              font-weight: bold;
              color: #0d1e34;
            }
          }

        }

        &.row-level-4 {
          margin-bottom: 18px;
          a {
            &:hover {
              span {
                color: $main-color;
              }
            }
          }
          .content-level-4 {
            text-decoration: none;
            span {
              font-size: 15px;
              font-weight: normal;
              color: #0d1e34;
            }

            .crop {
              position: relative;
              width: 120px;
              height: 120px;
              overflow: hidden;
              border-radius: 3px;
              img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
              & + span {
                padding-top: 5px;
                display: block;
                text-align: center;
                width: 120px;
              }
            }
            img {
              display: block;
              height: 120px;
              border-radius: 3px;
            }

          }
        }
      }
    }
  }

  @media screen and (max-width: 719px) {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    padding: 60px 0;
    transition: left 0.25s ease-in-out;
    background-image: linear-gradient(55deg, $background-color-1, $background-color-2);
    z-index: 3;

    &.isOpen {
      left: 0;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      img {
        display: none;
      }
      &.list-level-3 {
        background-color: rgba(0, 0, 0, 0.2);
        margin-top: 10px;
      }
      &.list-level-4 {
        padding: 10px 0 10px 0;
      }
    }
    nav {
      ul {
        li {
          a {
            font-size: 16px;
            line-height: 16px;
            font-weight: normal;
            color: #ffffff;
            text-decoration: none;
            display: block;
          }

          &.row-level-2 {
            position: relative;
            //margin-top: 18px;

            &.home, &.glxy-btn-home {
              i {
                margin: 0 0 0 -13px;
                &:after {
                  content: "Accueil";
                  margin-left: 30px;
                  font-style: normal;
                  position: absolute;
                  top: 2px;
                }
              }
            }

            & > a, & > div {
              display: block;
              height: 39px;
              line-height: 39px;
              padding: 0 45px;
              color: white;
              //pointer-events: none;
              span {
                pointer-events: none;
              }
            }

            .list-level-3 {
              margin: 0;
              display: none;
            }
            &.active {
              & > a {
                background-color: rgba(0, 0, 0, 0.4);
              }
              .list-level-3 {
                display: block;
              }

            }
          }

          &.row-level-4 {
            position: relative;
            padding: 0 50px 0 63px;
            min-height: 32px;
            a {
              -webkit-touch-callout: none;
              user-select: none;
            }
            span {
              -webkit-touch-callout: none;
              position: absolute;
              width: calc(100% - 80px);
              top: 50%;
              transform: translateY(-50%);
              color: #ffffff;
              font-size: 15px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 0;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.1);
              transition: width 0.5s ease-in-out;
            }
            &:hover, &:active {
              &:before {
                width: 100%;
              }
            }
          }

          &.row-level-3 {
            position: relative;
            color: #ffffff;
            font-size: 16px;

            .content-level-3 {
              position: relative;
              padding: 17px 10px 0 63px;
              &:before {
                position: absolute;
                top: 24px;
                left: 51px;
                content: "";
                display: block;
                height: 4px;
                width: 4px;
                border-radius: 2px;
                background-color: #ffffff;
              }
            }
            .arrow-wrapper {
              display: none;
              // TODO handling sub level button hidden
            }

            &.no-title {

              .list-level-4 {
                padding: 0;
                .row-level-4 {
                  span {
                    color: #ffffff;
                  }
                }
              }

            }
          }
        }

      }
    }

    .content-level-3, .row-level-2 {
      position: relative;
    }

    .row-level-2 {
      &:hover {
        & > a {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

}