.typer {
  text-decoration: none;
  color: #121212;
  cursor: pointer;

  @-webkit-keyframes blinker {
    from {
      opacity: 1.0;
    }
    to {
      opacity: 0.0;
    }
  }

  span {
    user-select: none;
    margin-left: 2px;
    &.blink {
      text-decoration: blink;
      animation-name: blinker;
      animation-duration: 0.6s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      animation-direction: alternate;
    }
  }
}