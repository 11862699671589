#tooltip {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-shadow: 1px 5px 5px 1px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: absolute;
  top: 70px;
  width: 300px;
  z-index: 10;

  &::before {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 0;
    border-left: 0;
    content: '';
    height: 10px;
    left: 120px;
    position: absolute;
    top: -6px;
    transform: rotate(-45deg);
    width: 10px;
  }

  @media screen and (min-width: 720px) {
    .btn-service {
      display: inline-block;
      margin-bottom: 4px;

      &:not(:nth-child(3n)) {
        margin-right: 3%;
      }
    }
  }

  @media screen and (max-width: 719px) {
    left: 50%;
    transform: translateX(-50%);
    width: 80%;

    .container-service {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      .btn-service {
        height: 20vw;
        margin: 10px auto;
        width: 20vw;
      }

      &::after {
        content: '';
        display: inline;
        height: 20vw;
        margin: 10px auto;
        width: 20vw;

      }
    }

    &::before {
      left: 25%;
      transform: translateX(-50%) rotate(-45deg);
    }
  }
}
